import request from '@/axios/index.js';
import axios from 'axios';

//精选壁纸
export function hotspot_wallpaper(query) {
    return request({
        headers: {
            port: 9110
        },
        url: '/wallpaper/official_website/hotspot_wallpaper',
        method: 'get',
        params: query
    })
}

//壁纸详情
export function details(query) {
    return request({
        headers: {
            port: 9110
        },
        url: '/wallpaper/official_website/details',
        method: 'get',
        params: query
    })
}
//资源检索
export function official(query) {
    return request({
        headers: {
            port: 9500
        },
        url: '/search/pc/official',
        method: 'get',
        params: query
    })
}

//资源检索
export function wallpaperList(query) {
    return request({
        headers: {
            port: 9110
        },
        url: '/wallpaper/lists',
        method: 'post',
        data: query
    })
}



//官网文章分类管理 - PC分类查询
export function getClassification(query) {
    return request({
        headers: {
            port: 9999
        },
        url: '/official/article_classification/getClassification',
        method: 'get',
        params: query
    })
}

//官网文章管理 - PC分页查询(根据文章分类查询)
export function getArticleByClassification(query) {
    return request({
        headers: {
            port: 9999
        },
        url: '/official/official_article/getArticleByClassification',
        method: 'get',
        params: query
    })
}


//官网文章管理 - PC推荐文章展示
export function recommends(query) {
    return request({
        headers: {
            port: 9999
        },
        url: '/official/official_article/recommends',
        method: 'get',
        params: query
    })
}

//官网文章管理 - PC分页查询(根据文章标签查询)
export function getArticleByLabel(query) {
    return request({
        headers: {
            port: 9999
        },
        url: '/official/official_article/getArticleByLabel',
        method: 'get',
        params: query
    })
}

//官网文章管理 - PC根据文章ID查询详情
export function getDetailById(query) {
    return request({
        headers: {
            port: 9999
        },
        url: '/official/official_article/getDetailById',
        method: 'get',
        params: query
    })
}

//首页轮播
export function getCarousels() {
    return request({
        headers: {
            port: 9999
        },
        url: '/official/official_ads/recommends',
        method: 'get'
    })

    //return axios.get('http://192.168.0.220:3000/mock/225/official/official_ads/recommends')
}

export function getConnect() {
    return axios.get('https://source.apumiao.com/official_resources/guanyu.json')

    //return axios.get('http://192.168.0.220:3000/mock/225/official/official_ads/recommends')
}