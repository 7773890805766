<template>
  <div :class="['_article_item', isActived ? 'actived' : '']">
    <div v-if="list.length">
      <div class="_article_list">
        <div
          v-for="(arc, index) in list"
          :key="index"
          @click="goInfo(arc)"
          :class="['_article_box', arc.replenish ? '_none' : '']"
        >
          <template v-if="arc.replenish"> </template>
          <template v-else>
            <div class="_img">
              <img src="../../assets/img/recommends/logo.png" class="__bg" />
              <img :src="arc.cover_url" class="__cover" />
            </div>
            <div class="_text">
              <p>
                <span class="_title_tag" v-if="label">{{
                  arc.classification_name
                }}</span>
                {{ arc.title }}
              </p>
              <span class="_time">{{ arc.create_time }}</span>
              <div class="_desc">{{ arc.content }}</div>
              <div class="_labels">
                <span
                  v-for="(lb, idx) in arc.label"
                  :key="idx"
                  @click="goLabel(lb, $event)"
                  >{{ lb }}</span
                >
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="_pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
        <span style="color: #161616">共{{ total }}条记录</span>
      </div>
    </div>
    <div v-else>
      <div class="_empty" v-if="!list_init">
        <img src="../../assets/img/recommends/empty.png" alt="" />
        <p>暂无{{ words ? "搜索结果" : "内容" }}哦~</p>
      </div>

      <div class="_loading" v-else>
        <img src="../../assets/img/recommends/loading.gif" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import {
  getArticleByClassification,
  getArticleByLabel,
} from "../../api/wallpaper";
export default {
  name: "recommends_item",
  props: {
    id: null,
    isActived: false,
    label: null,
    words: null,
  },
  data() {
    return {
      init: true,
      list_init: true,
      list: [],
      pageSize: 12,
      current: 1,
      total: 0,
    };
  },
  watch: {
    isActived(v) {
      if (v && this.init) {
        this.init = false;
        this.getArticles();
      }
    },
  },
  mounted() {
    if (this.isActived) {
      /* 第一页 */
      this.init = false;
      this.getArticles();
    }
  },
  methods: {
    changeActive(idx) {
      if (this.actived !== idx) {
        this.actived = idx;
      }
    },
    doclear() {
      this.words = "";
    },
    handleCurrentChange(v) {
      this.current = v;
      this.getArticles();
      this.$scrollbar.wrap.scrollTop = 0;
      //document.documentElement.scrollTop = 0;
    },
    removeXMLTag(content) {
      return content ? content.replace(/<.*?>/g, "") : "";
    },
    getLabels(label) {
      return label ? label.split(",") : [];
    },
    async getArticles() {
      let res = null;
      if (this.label) {
        res = await getArticleByLabel({
          current: this.current,
          size: this.pageSize,
          label: this.label,
        });
      } else {
        let data = {
          current: this.current,
          size: this.pageSize,
        };
        if (this.words) data = Object.assign({}, { title: this.words }, data);
        else data = Object.assign({}, { classification: this.id }, data);
        res = await getArticleByClassification(data);
      }

      if (res.success) {
        let arr = res.data.records.map((item) => {
          item.content = this.removeXMLTag(item.content);
          item.label = this.getLabels(item.label);
          return item;
        });
        if (arr.length % 3 == 2)
          arr.push({
            replenish: true,
          });
        this.total = Number(res.data.total);
        this.list = arr;
        this.list_init = false;
      }
    },
    goInfo(item) {
      if (item.replenish) return;
      let data = this.$router.resolve({
        path: "/realInfo_info",
        query: { id: item.id, label: this.label },
      });
      window.open(data.href, "_blank");
    },
    goLabel(label, e) {
      e.stopPropagation();
      const data = this.$router.resolve({
        path: "/acticle_type",
        query: { label: label },
      });
      window.open(data.href, "_blank");
    },
  },
};
</script>
<style scoped lang="scss">
._article_item {
  width: 1300px;
  height: auto;
  display: none;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  border-top: solid 1px transparent;
  &.actived {
    display: block;
  }
  ._pagination {
    clear: both;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 60px 0;
    .el-pagination {
      text-align: center;
      /deep/.el-icon-arrow-left {
        &::before {
          content: "\25C0";
        }
      }
      /deep/.el-icon-arrow-right {
        &::before {
          content: "\25B6";
        }
      }
    }
  }
  ._article_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    ._article_box {
      width: 420px;
      height: 416px;
      position: relative;
      margin: 17px 0 20px;
      cursor: pointer;
      &._none {
        cursor: auto;
      }
      ._img {
        width: 388px;
        height: 218px;
        background: #d3e2fc;
        border: 1px solid rgba(223, 223, 223, 0.94);
        border-radius: 16px;
        position: relative;
        z-index: 2;
        margin-left: 16px;
        overflow: hidden;
        .__bg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        }
        .__cover {
          width: 100%;
          position: relative;
          z-index: 2;
        }
      }
      ._text {
        width: 388px;
        margin-top: -182px;
        padding: 182px 16px 0;
        height: 198px;
        background: #ffffff;
        border: 1px solid rgba(223, 223, 223, 0.94);
        border-radius: 16px;
        position: relative;
        z-index: 1;
        p {
          font-size: 18px;
          font-weight: bold;
          color: #161616;
          margin: 19px 0 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          ._title_tag {
            float: left;
            padding: 3px 8px;
            background: #3da7fe;
            border-radius: 8px;
            margin-right: 16px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            &:hover {
              background-color: #2197f9;
            }
          }
        }
        ._time {
          display: block;
          font-size: 12px;
          font-weight: 400;
          color: #161616;
          opacity: 0.5;
          margin-bottom: 16px;
        }
        ._desc {
          font-size: 14px;
          font-weight: 400;
          color: #161616;
          line-height: 20px;
          opacity: 0.7;
          overflow: hidden;
          height: 40px;
          margin-bottom: 24px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        ._labels {
          text-align: right;
          span {
            display: inline-block;
            background: rgba(61, 167, 254, 0.1);
            border-radius: 12px;
            font-size: 14px;
            font-weight: 400;
            color: #3da7fe;
            padding: 3px 8px;
            margin: 0 4px;
            cursor: pointer;
            &:hover {
              background: #3da7fe;
              color: #ffffff;
            }
          }
        }
      }
      &:hover {
        ._text {
          box-shadow: 0px 0px 16px 0px rgba(22, 22, 22, 0.1);
        }
      }
    }
  }
}
._empty {
  margin: 122px auto 203px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #161616;
  img {
    width: 208px;
    margin: 0 auto 20px;
  }
}
._loading {
  img {
    width: 400px;
    margin: 0 auto;
  }
}
</style>
