<template>
  <div>
    <div class="__bg">
      <img src="../../assets/img/recommends/bg.png" alt="" />
    </div>
    <div class="__content">
      <div class="_type_title">
        <div class="_type_title_inner">
          <a @click="goBack()">精选推荐</a>
          <img src="../../assets/img/recommends/arrow.png" class="arrow" />
          <span>{{ label }}</span>
        </div>
      </div>
      <ArticleList :label="label" :isActived="true" />
    </div>
  </div>
</template>
<script>
import ArticleList from "./article_list";
export default {
  name: "typelist",
  data() {
    return {
      label: "",
    };
  },
  components: {
    ArticleList,
  },
  created() {
    this.label = this.$route.query.label ? this.$route.query.label : 0;
  },
  methods: {
    goBack() {
      this.$router.push({ path: "/recommends" });
    },
  },
};
</script>
<style lang="scss" scoped>
.__bg {
  position: fixed;
  left: 0;
  top: 70px;
  width: 100%;
  height: 332px;
  z-index: 2;
  img {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
  }
}
.__content {
  position: relative;
  z-index: 3;
}
.__wl {
  display: block;
  content: " ";
  background: #8ea9fe;
  position: absolute;
  bottom: 0;
  z-index: 1;
}
._type_title {
  width: 1300px;
  font-size: 16px;
  font-weight: 400;
  color: #161616;
  height: 24px;
  line-height: 24px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 60px 0 37px;
  border-bottom: 1px solid #e9f0f6;
  ._type_title_inner {
    width: auto;
    max-width: 497px;
    position: relative;
    float: left;
    padding-right: 12px;
    div {
      position: relative;
      z-index: 2;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &::before {
      @extend .__wl;
      width: 100%;
      height: 8px;
      opacity: 0.5;
      left: 0;
    }
  }
  span {
    font-weight: bolder;
    display: inline;
    font-size: 18px;
  }
  .arrow {
    display: inline;
    width: 29px;
    height: 11px;
    margin: 0 3px;
  }
  a {
    &:hover {
      color: #3da7fe;
    }
  }
  &::after {
    @extend .__wl;
    width: 50px;
    height: 9px;
    border-radius: 2px;
    right: 0;
  }
}
</style>
